// @formatter:off
import {intl_int, intl_num, intl_num_2, intl_num_4} from './i18n-util';
import {I18nParametric, I18nSimpleKey} from './i18n';

const msgs = {
  '1990': '1990',
  '1991': '1991',
  '1992': '1992',
  '1993': '1993',
  '1994': '1994',
  '1995': '1995',
  '1996': '1996',
  '1997': '1997',
  '1998': '1998',
  '1999': '1999',
  '2000': '2000',
  '2001': '2001',
  '2002': '2002',
  '2003': '2003',
  '2004': '2004',
  '2005': '2005',
  '2006': '2006',
  '2007': '2007',
  '2008': '2008',
  '2009': '2009',
  '2010': '2010',
  '2011': '2011',
  '2012': '2012',
  '2013': '2013',
  '2014': '2014',
  '2015': '2015',
  '2016': '2016',
  '2017': '2017',
  '2018': '2018',
  '2019': '2019',
  '2020': '2020',
  '2021': '2021',
  '2022': '2022',
  '2023': '2023',
  '2024': '2024',
  '2025': '2025',
  '2026': '2026',
  '2027': '2027',
  '2028': '2028',
  '2029': '2029',
  '2030': '2030',
  '2031': '2031',
  '2032': '2032',
  '2033': '2033',
  '2034': '2034',
  '2035': '2035',
  'ARG': 'Argentina',
  'AUS': 'Australia',
  'AUT': 'Austria',
  'Accept': 'Accept',
  'AcceptOrDecline': 'Accept/Decline',
  'AddAnotherArea': 'Add Another Area',
  'AddAssignee': 'Add Assignee',
  'AddAttachments': 'Add Attachments',
  'AddCause': 'Add a cause',
  'AddClaim': 'Add claim',
  'AddCost': 'Add a cost',
  'AddCrop': 'Add crop type',
  'AddEditSampleShape': 'Draw affected area on the map',
  'AddEditor': 'Add Editor',
  'AddEditors': 'Add editors',
  'AddEditorsOneGroup': 'Please add editors to items in one portfolio only',
  'AddFarm': 'Add Farm',
  'AddFarmInfo': 'Please note that to add new/additional plots to an EXISTING farm, it is required to first search it by Farm Name or Farm Reference in the corresponding fields below.',
  'AddField': 'Add New Field',
  'AddFieldShape': 'Add Field Shape',
  'AddFieldShapes': 'Add Field Shapes',
  'AddFieldsHarvests': 'Add Fields & Harvests',
  'AddFromFileManager': 'Add from File Manager',
  'AddFromPhotos': 'Add from Photos',
  'AddGuaranteedLossCause': 'Add a guaranteed loss cause',
  'AddHarvest': 'Add new Harvest',
  'AddLossCause': 'Add a loss cause',
  'AddNewHarvest': 'Create a new Harvest',
  'AddNewHarvestFor': 'Create a new Harvest for',
  'AddNewSubparcel': 'Add new sub-plot',
  'AddOrEditFields': 'Add/edit fields',
  'AddPhoto': 'Add Photo',
  'AddPhotoError': 'Adding photo failed',
  'AddPolicy': 'Add new Contract',
  'AddRecipient': 'Add recipient',
  'AddSample': 'Add Sample',
  'AddSamplePhoto': 'Add Photo to Sample',
  'AddSubplotId': 'Add Subplot ID',
  'AddUnguaranteedLossCause': 'Add an unguaranteed loss cause',
  'AddUser': 'Add user',
  'AddVarietyHarvest': 'Add variety',
  'AddedBy': 'Added By',
  'AddedGroups': 'Added groups',
  'AddedOn': 'Added On',
  'AdditionalInfo': 'Additional Info',
  'AdditionalQuestions': 'Additional Questions',
  'AdvancedOptions': 'Advanced Options',
  'AffectedArea': 'Affected Area',
  'AffectedCrop': 'Affected crop',
  'AffectedCrops': 'Affected crops',
  'AffectedCropsRequiredToDeclareLoss': 'You must select at least one affected crop in order to add declared losses.',
  'AffectedField': 'Affected Field',
  'All': 'All',
  'AllSynced': 'All Synced',
  'AllTime': 'All Time',
  'AlsoInheritedFrom': 'Also inherited from',
  'Amount': 'Amount ($)',
  'AppVersionDeprecated': 'App version deprecated',
  'AppVersionDeprecatedMessage': 'You app version is deprecated. You must refresh the page to get the new version. Click REFRESH to refresh the page. If you have any unsaved data, click cancel and manually backup the data and then refresh the page.',
  'Area': 'Area',
  'AreaCropTitle': 'Area by crop type',
  'Ascending': 'ascending',
  'Assign': 'Assign',
  'AssignExistingFields': 'Allocate existing fields',
  'AssignedLossAdjusters': 'Assigned loss adjusters',
  'AssignedTo': 'Assigned to',
  'AssociatedEmails': 'Associated E-mails',
  'Attachment': 'Attachment',
  'Attachments': 'Attachments',
  'Average': 'Average',
  'AverageFieldArea': 'Average field area',
  'AvgBollsPerPlant': 'Average number of bolls per plant',
  'AvgEarsPerPlant': 'Average number of ears per plant',
  'AvgGrainsPerEar': 'Average number of grains per ear',
  'AvgGrainsPerPod': 'Average number of grains per pod',
  'AvgGrainsPerSilique': 'Average number of grains per silique',
  'AvgKernelsPerBoll': 'Average number of kernels per boll',
  'AvgPodsPerShaft': 'Average number of pods per shaft',
  'AvgSiliquesPerShaft': 'Average number of siliques per shaft',
  'BEL': 'Belgium',
  'BRA': 'Brazil',
  'Back': 'Back',
  'BackToHome': 'Back to home',
  'Benchmark': 'Benchmark',
  'Biomass': 'Biomass',
  'BunchWeight': 'Average weight per bunch of grapes in grams',
  'BunchesPerPlant': 'Average number of bunches of grapes per plant',
  'ByCrop': 'By crop',
  'ByField': 'By field',
  'ByFieldArea': 'By field area',
  'ByFieldCount': 'By field count',
  'CAN': 'Canada',
  'CHE': 'Switzerland',
  'CZE': 'Czechia',
  'Calculate': 'Calculate',
  'CalculateAbbreviated': 'Calc.',
  'Cancel': 'Cancel',
  'Change': 'Change',
  'ChangeOverrideSampleMsg': 'Are you sure you want to change the override value for this crop?',
  'ChangeUserAccessRights': 'Change user access rights',
  'CheckForCodepushUpdate': 'Check for updates',
  'CirclesArea': 'Circle\'s Area',
  'CirclesDiameter': 'Circle\'s Diameter',
  'CirclesRadius': 'Circle\'s Radius',
  'Claim': 'Claim',
  'ClaimNumber': 'Claim Number',
  'ClaimRequests': 'Claim requests',
  'ClaimStatus_adjuster-accepted': 'Adjuster accepted',
  'ClaimStatus_assessment-in-progress': 'Assessment in progress',
  'ClaimStatus_awaiting-adjuster-acceptance': 'Awaiting adjuster acceptance',
  'ClaimStatus_completed': 'Completed',
  'ClaimStatus_created-unassigned': 'Created & unassigned',
  'ClaimStatus_payment-proposal-sent': 'Payment proposal sent',
  'ClaimStatus_rejected': 'Rejected',
  'ClaimStatus_visit-date-defined': 'Visit date defined',
  'ClaimStatus_visit-report-created': 'Visit report created',
  'ClaimStatus_visit-samples-collected': 'Visit started',
  'Claims': 'Claims',
  'Clear': 'Clear',
  'ClearAll': 'Clear All',
  'Clearing': 'Clearing',
  'ClickHereToAddFarmerEmail': 'Click here to add farmer e-mail',
  'ClickUpload': 'Click to Upload',
  'Close': 'Close',
  'CloseVisit': 'Close visit',
  'Closed': 'Closed',
  'ClosedVisits': 'Closed Visits',
  'CloudCover': 'Cloud Cover',
  'CloudCoverInfo': 'This column shows highlights if any year has been too cloudy for reliable productivity measurement (> 50% of obscured weeks during the peak period)',
  'CloudCoverWarning': 'Cloud cover warning',
  'CommentInfoWarning': 'Warning! Does not appear on reports.',
  'Comments': 'Comments',
  'CompassHeading': 'Heading',
  'CompensableLosses': 'Compensable Losses',
  'Condition': 'Condition',
  'Configuration': 'Configuration',
  'Confirm': 'Confirm',
  'Contact': 'Contact us',
  'ContainsCopernicus': 'This contains modified Copernicus Service information (2017)',
  'ContinueWithErrorsAndWarnings': 'Continue without fixing errors and warnings?',
  'ConversionRatio': 'Conversion ratio',
  'CopyCurrentHarvest': 'Copy current harvest',
  'CopyHarvestFromPreviousYears': 'Copy harvest from previous years',
  'CopyLink': 'Copy link',
  'CopySample': 'Copy Sample',
  'CopyVisit': 'Copy visit',
  'CostType': 'Cost Type',
  'Costs': 'Costs ($)',
  'CouldntDelete': 'Unable to delete this item. You need to be online to delete.',
  'CreateNewVisit': 'Create new visit',
  'CropCondition': 'Crop condition',
  'CropConditionBreakdown': 'Distribution of Crop Conditions',
  'CropCoverage': 'Crop Coverage',
  'CropCoverageInfo': 'This shows the percentage of the field covered by the crop of interest',
  'CropType-s': 'Crop Type(s)',
  'CropVariety': 'Variety',
  'CropYieldAverageTitle': 'Average Estimated Yield',
  'CurHarvest': 'Current Harvest',
  'CurrentFilters': 'Current Filters',
  'CurrentVisit': 'Current Visit',
  'CurrentVisits': 'Current Visits',
  'Customer': 'Customer',
  'DEU': 'Germany',
  'DamagedFields': 'Damaged fields',
  'Dashboard': 'Dashboard',
  'Date': 'Date',
  'Date-s': 'Date(s)',
  'DateRange': 'Date Range',
  'DeclaredHazard': 'Declared Hazard',
  'DeclaredLossCauses': 'Declared loss causes',
  'Decline': 'Decline',
  'Deductible': 'Deductible',
  'Deductible-s': 'Deductible(s)',
  'Delete': 'Delete',
  'DeleteForEveryone': 'Delete for everyone',
  'DeleteOverrideSampleMsg': 'Are you sure you want to delete the override estimate? If yes, we will fallback to the samples to derive the crop-level aggregated estimated value.',
  'DeleteUser': 'Delete user',
  'DeleteUsers': 'Delete users',
  'Delimiter': 'Delimiter',
  'Descending': 'descending',
  'DetachedSample-s': 'Detached Sample(s)',
  'Details': 'Details',
  'DetectDensity': 'Detect Density',
  'DisablePoiMode': 'Show all farms',
  'Done': 'Done',
  'Download': 'Download',
  'DownloadGeojson': 'Download GeoJSON file',
  'DownloadXLSX': 'Download Excel file',
  'Downloading': 'Downloading',
  'DownloadingInitialData': 'Downloading initial data',
  'Draw': 'Draw',
  'DuplicateEntryWasRemoved': 'The following entry already exists and will not be imported:',
  'ESP': 'Spain',
  'EarDensity': 'Ear Density',
  'Edit': 'Edit',
  'EditClaim': 'Edit claim',
  'EditFarm': 'Edit Farm',
  'EditField': 'Edit Field',
  'EditFieldShape': 'Edit Field Shape',
  'EditHarvest': 'Edit Harvest',
  'EditPolicy': 'Edit Contract',
  'EditSample': 'Edit Sample',
  'Editors': 'Editors',
  'Email': 'Email',
  'EmailMissing': 'Please input your email!',
  'Emergence': 'Emergence',
  'EmergenceDate': 'Emergence Date',
  'EnableNow': 'Enable Now',
  'EnablePoiMode': 'Show interesting farms only',
  'EnablePreciseLocation': 'Please enable Precise Location',
  'EndDate': 'End Date',
  'Entity': 'Entity',
  'ErrMissingFields': 'Please complete all the underlined fields above.',
  'Error': 'Error',
  'EstimatedLossAbbr': 'Est. Loss',
  'EstimatedYield': 'Estimated Yield',
  'EstimatedYieldAbbr': 'Est. Yield',
  'EstimatedYieldAreaWeighted': 'Estimated yield (area weighted)',
  'EstimatedYieldArithmeticMean': 'Estimated yield (arithmetic mean)',
  'EstimatedYieldLoss': 'Estimated Yield Loss',
  'Estimation': 'Estimation',
  'EventDate': 'Date of event',
  'EventValidation': 'Event validation',
  'Existing': 'Existing',
  'ExistingClaim': 'Existing Claim',
  'ExistingClaimMsg': 'There is an existing claim for this farm. Would you like to continue with the existing claim?',
  'ExistingFarm': 'This farm already exists.',
  'ExoBinUnsupported': 'This feature is currently not supported on this version of the app. Please update the app to the latest version.',
  'ExoCamera': 'Exo Camera',
  'ExoDetectionError': 'Unable to detect ears. Try again by adjusting the position or by capturing a new image.',
  'ExoModelNotDownloaded': 'Exo model not downloaded. Please connect to the internet to download the model for offline use.',
  'ExoNotEnabledForUser': 'This feature is not enabled for your company or user group. Please contact your administrator.',
  'ExoTutorialPanDescription': 'Move the image around using one finger.',
  'ExoTutorialPinchDescription': 'Pinch the image to zoom in and out.',
  'ExoTutorialSearchDescription': 'Align the detection zone with the intended part of the image, until it turns green.',
  'Expert': 'Expert',
  'Expert-s': 'Expert(s)',
  'ExpertName': 'Expert Name',
  'ExpertRanking': 'Expert Ranking',
  'ExpertReport': 'Farm Report',
  'ExternalClaimId': 'External claim id',
  'ExternalFieldId': 'External field id',
  'ExtremelyHighTemperature': 'Extreme heat wave (Tmax>38°C)',
  'ExtremelyLowTemperature': 'Extreme frost risk (-15°C>Tmin)',
  'FRA': 'France',
  'FailedToSyncChanges': 'Failed to sync changes',
  'Farm': 'Farm',
  'FarmAddress': 'Farm Address',
  'FarmAndHarvest': 'Farm & harvest info',
  'FarmDeletedPleaseStartOver': 'Farm was deleted! Please start over',
  'FarmFormComment': 'Additional operating information',
  'FarmHarvestLevel': 'Harvest-level',
  'FarmHarvestLevelObservation': 'Harvest-level Observation',
  'FarmHarvests': 'Farm Harvests',
  'FarmInfo': 'Farm Info',
  'FarmLocationPrompt': 'Hard-press on the Map to set Farm location or press the button on the left to use your location',
  'FarmName': 'Farm Name',
  'FarmReference': 'Farm reference',
  'Farmer': 'Farmer',
  'FarmerName': 'Farmer Name',
  'FarmerOrRepresentative': 'Farmer or representative',
  'FarmerRepresentative': 'Farmer representative',
  'FarmersEstimatedYieldLoss': 'Farmer\'s estimated yield loss',
  'Farms': 'Farms',
  'FeasibleYield': 'Feasible Yield',
  'FeasibleYieldEmpty': 'Feasible yield is empty.',
  'Feedback': 'Feedback',
  'FetchReportOfflineInfo': 'Looks like your are offline, hence the report can\'t be fetched.',
  'Field': 'Field',
  'Field-s': 'Field(s)',
  'FieldArea': 'Field Area',
  'FieldAreaAndReference': 'Field area & reference',
  'FieldCultivatedArea': 'Cultivated area (field)',
  'FieldFormComment': 'Additional plot information',
  'FieldHarvests': 'Field Harvests',
  'FieldInfo': 'Field Info',
  'FieldLevel': 'Field-level',
  'FieldLocationPrompt': 'Hard-press on the Map to set Field location or press the button on the left to use your location',
  'FieldManagement': 'Field Management',
  'FieldMissingCurPolicyHarvest': 'This field doesn\'t have a current Contract and a Harvest assigned; please click here to assign one.',
  'FieldReference': 'Field Reference',
  'FieldScoring': 'Field Scoring',
  'FieldShapeAndFarm': 'Field shape & farm',
  'Fields': 'Fields',
  'FileActions': 'File actions',
  'FilterList': 'Filter List',
  'Filters': 'Filters',
  'FirstAndLastName': 'First & last name',
  'FirstName': 'First name',
  'FloodZone': 'Flood zone',
  'ForgotPassword': 'Reset Password',
  'FormSubmitted': 'Saved!',
  'FrontPageReportDisclaimer': 'Subject to the validity of guarantees',
  'FungicideApplied': 'Fungicide Applied',
  'GPSModuleUnavailable': 'GPS module unavailable',
  'Generate': 'Generate',
  'GenerateReport': 'Generate Report',
  'GeneratingReportPleaseWait': 'Generating report, please wait...',
  'GeographicalArea': 'Geographical Area',
  'GetDirectionsField': 'Get Directions to Field',
  'GrainMoisture': 'Grain moisture',
  'GrainMoistureReduction': 'Reduction due to grain moisture',
  'GrantsTo': 'Grants to',
  'GrapeVariety': 'Grape Variety',
  'GrossSampleProduction': 'Gross sample production',
  'GuaranteedLosses': 'Guaranteed Losses',
  'HUN': 'Hungary',
  'HailInfo': 'This layer displays the risk of hail events.',
  'Harvest': 'Harvest',
  'HarvestArea': 'Crop Area',
  'HarvestAreaMustBePositiveAndLessThanFieldArea': 'Harvest area must be positive and not larger than field area!',
  'HarvestFormComment': 'Additional harvest information',
  'HarvestInfo': 'Harvest Info',
  'HarvestLoss': 'Harvest Loss',
  'HarvestModificationDisabledMsg': 'This harvest cannot be modified, as it has been created by the insurer.',
  'HarvestPlotId': 'Subplot ID',
  'HarvestPractice': 'Harvest Practice',
  'HarvestReference': 'Harvest Reference',
  'HarvestYear': 'Harvest Year',
  'Harvester': 'Harvester',
  'HarvesterDistanceHarvested': 'Distance harvested',
  'HarvesterPlatformWidth': 'Platform Harvesting width',
  'Harvests': 'Harvests',
  'HelloReportIssue': 'Hello, I would like to report the following issue with the GreenTriangle App:',
  'HerbicideApplied': 'Herbicide Applied',
  'HereAddAsNew': 'Click here to add as a new farm instead.',
  'HideDataFromMap': 'Hide data from map',
  'HighHailRisk': 'High Hail Damage Risk',
  'HighPWind': 'Storm (64-73 mph)',
  'HighPrecipitation': 'High precipitation (1.2-2 in/wk)',
  'HighSoilMoisture': 'Slightly wetter than usual',
  'HighTemperature': 'Light heat wave (77°F<Tmax<84°F)',
  'HighVegetation': 'Slightly higher vegetation health than usual (+25%)',
  'Higher': 'Higher',
  'HigherSoilMoisture': 'Wetter than usual',
  'HigherTemperature': 'Moderate heat wave (84°F<Tmax<93°F)',
  'HigherVegetation': 'Higher vegetation health than usual (+40%)',
  'Highest': 'Highest',
  'HighestSoilMoisture': 'Much wetter than usual',
  'HighestTemperature': 'Heavy heat wave (Tmax>93°F)',
  'HighestVegetation': 'Much higher vegetation health than usual (+55% or more)',
  'HistoricalDeclaredYields': 'Historical declared yields',
  'HistoricalObservedYields': 'Historical observed yields',
  'HistoricalRealizedYields': 'Historical realized yields',
  'Homogeneity': 'Homogeneity',
  'HomogeneityInfo': 'The homogeneity score measures how homogeneous the field has historically been in terms of crop development',
  'ID': 'ID',
  'ITA': 'Italy',
  'ImportData': 'Import Data',
  'Impurities': 'Impurities',
  'Info': 'Info',
  'InheritedFrom': 'Inherited from',
  'Initials': 'Initials',
  'InsecticideApplied': 'Insecticide Applied',
  'Insee': 'INSEE',
  'Install': 'Install',
  'InsuredArea': 'Insured Area',
  'InsuredCrops': 'Insured Crops',
  'InsuredPrice': 'Insured Price',
  'InsuredProduction': 'Insured Production',
  'InsuredYield': 'Insured Yield',
  'InterfieldInfo': '"Field Comparison" displays the absolute vegetation health and allows direct comparison of the general vegetation condition between different plots.',
  'InteryieldInfo': 'Yield prediction displays the predicted yield based on the remote sensing and climate data available up to the stated date.',
  'IntrafieldInfo': 'Intra Field Variability displays the normalized vegetation health for the plot being monitored. The plot is subdivided into 5 zones of equal area corresponding to 20% (or quintile) of the index values, from the lowest to the highest.',
  'InvalidShapeTraced': 'Your shape cannot have intersecting lines. Please draw a simple shape without crossings.',
  'Irrigated': 'Irrigated',
  'IrrigatedQuestionMark': 'Irrigated?',
  'KernelsPerEar': 'Kernels per Ear',
  'KernerlsPerUnit': 'Kernels per unit',
  'LTU': 'Lithuania',
  'LastChanged': 'Last changed',
  'LastName': 'Last name',
  'Legend': 'Legend',
  'LinkCopiedToClipboard': 'The link was copied to your clipboard',
  'LinkToThisLocation': 'Link to this location',
  'List': 'List',
  'LiveSampleFeed': 'Live Sample Feed',
  'Loading': 'Loading',
  'Locale': 'Language',
  'Location': 'Location',
  'LocationAndFarm': 'Location & Farm',
  'LocationMocked': 'Looks like your location is mocked. Please use your real location to proceed.',
  'LocationNotProvided': 'Location needs to be enabled to use this feature.',
  'Login': 'Login',
  'Logout': 'Logout',
  'LossAdjuster': 'Loss Adjuster',
  'LossCause': 'Loss Cause',
  'LossCauseRecognized': 'Loss Cause Recognized',
  'LossEstimation': 'Loss Estimation',
  'LossEventDate': 'Loss event date',
  'Losses': 'Losses',
  'LowHailRisk': 'Low Hail Damage Risk',
  'LowPrecipitation': 'Low precipitation (0.4-0.6 in/wk)',
  'LowSoilMoisture': 'Slightly drier than usual',
  'LowTemperature': 'Light frost risk (30°F<Tmin<36°F)',
  'LowVegetation': 'Slightly lower vegetation health than usual (-25%)',
  'LowWind': 'Strong wind (38-55 mph)',
  'Lower': 'Lower',
  'LowerSoilMoisture': 'Drier than usual',
  'LowerTemperature': 'Moderate frost risk (23°F<Tmin<30°F)',
  'LowerVegetation': 'Lower vegetation health than usual (-40%)',
  'Lowest': 'Lowest',
  'LowestSoilMoisture': 'Much drier than usual',
  'LowestTemperature': 'Heavy frost risk (23°F>Tmin)',
  'LowestVegetation': 'Much lower vegetation health than usual (-55% or less)',
  'MAR': 'Morocco',
  'MDA': 'Moldova',
  'MEX': 'Mexico',
  'ManageUserAccountAndSettings': 'Manage your accounts and settings',
  'Manager': 'Manager',
  'Map': 'Map',
  'MeasureDensity': 'Measure Density',
  'Median': 'Median',
  'Merge': 'Merge',
  'Method': 'Method',
  'MixedTemperature': 'Mixed high temperature fluctuation zone',
  'ModerateHailRisk': 'Moderate Hail Damage Risk',
  'ModeratePrecipitation': 'Moderate precipitation (0.6-1.2 in/wk)',
  'ModerateWind': 'Gale (55-64 mph)',
  'Months': 'Months',
  'MoreSpecificHarvestDetected': 'More specific harvest detected and will replace the existing harvest.',
  'MunicipalMedianVegetation': 'Municipality Median Vegetation',
  'Municipality': 'Municipality',
  'MustAddCustomer': 'Must add at least one customer',
  'MustAddEmail': 'Must provide a valid email',
  'MustSelectACustomer': 'Must select a customer to continue',
  'MustSelectCropAndYear': 'You must select a crop and a year',
  'NDVIRange': 'NDVI Range',
  'NationalYield': 'National Yield',
  'NeedCurrentHarvest': 'You need to add a harvest for this season before continuing.',
  'NeedHelpContact': 'Issues with your login? - Contact us',
  'New': 'New',
  'NewValue': 'New Value',
  'NewVersionWarning': 'A new version of the app is available.',
  'NewVisit': 'New visit',
  'Next': 'Next',
  'No': 'No',
  'NoClaims': 'No claims',
  'NoData': 'No data found.',
  'NoDataFoundForYourFilters': 'We have no data for your current filter criteria.',
  'NoFarmEditors': 'No editors assigned to this farm',
  'NoGpsSupport': 'This device does not have a GPS unit.',
  'NoHailRisk': 'No Damage Risk',
  'NoOfflineFarms': 'No farms saved for offline use.',
  'NoOngoingVisit': 'Please click on (+) above to start a new visit.',
  'NoPrecipitation': 'Very low or no precipitation (<0.2 in/wk)',
  'NoSampleDateSelectionWarning': 'No sampling date has been selected. Please select at least one to avoid having an empty report.',
  'NoTelepacAvailable': 'Telepac not available',
  'NoVariety': 'No Variety',
  'NoWind': 'Breeze or no wind (<38 mph)',
  'NonCompensableLosses': 'Non-compensable Losses',
  'NonIrrigated': 'Non-Irrigated',
  'NonOrganic': 'Non-Organic',
  'NormalSoilMoisture': 'As usual',
  'NormalTemperature': 'No frost/heat risk (36°F<Tmin; Tmax<77°F)',
  'NormalVegetation': 'Normal vegetation health',
  'NotAffected': 'Not affected',
  'NotAvailable': 'Not Available',
  'NumberMatchingFields': 'Number of Matching Fields',
  'NumberMatchingSamples': 'Number of Matching Samples',
  'NumberOfFields': 'Number of Fields',
  'NumberOfLossAdjusters': 'Number of Loss Adjusters',
  'NumberOfPolicies': 'Number of Contracts',
  'NumberOfSamples': 'Number of Samples',
  'Occurence': 'Occurence',
  'OfFieldArea': 'of field area',
  'OfficialHarvestArea': 'Official harvest area',
  'Offline': 'Offline',
  'OfflineAreas': 'Offline Areas',
  'OfflineFarms': 'Offline Farms',
  'Ok': 'Ok',
  'Ongoing': 'Ongoing',
  'OpenCamera': 'Open Camera',
  'OpenFarm': 'Open Farm',
  'OpenPolicy': 'Open Contract',
  'OpenVisit': 'Open visit',
  'Or': 'Or',
  'Organic': 'Organic',
  'OrganicQuestionMark': 'Organic?',
  'OtherCompensableLosses': 'Other compensable Losses',
  'OtherFields': 'Other Fields',
  'OverrideCalculation': 'Override aggregated yield',
  'OverrideEstimatedYield': 'Override Estimated Yield',
  'Overview': 'Overview',
  'PER': 'Peru',
  'POL': 'Poland',
  'PRY': 'Paraguay',
  'Password': 'Password',
  'PasswordMissing': 'Please input your password!',
  'PastMonth': 'Past Month',
  'PastReportsClickToRefresh': 'Past reports - click to refresh:',
  'PastWeek': 'Past Week',
  'PastYear': 'Past Year',
  'Pause': 'Pause',
  'PeakVegetationDate': 'Peak Vegetation Date',
  'PendingDownload': 'Pending Download',
  'PendingUpload': 'Pending upload',
  'PerCropSignature': 'Per-crop signature',
  'Phone': 'Phone',
  'Photo': 'Photo',
  'Photos': 'Photos',
  'PhotosAndReferences': 'Photos & References',
  'PixelResolution': 'Pixel resolution',
  'PlanetTiles': 'Planet Imagery',
  'PlanetTilesBoundsWarning': 'No tiles are available for selected date and area. Please select a different date or area.',
  'PlantDensity': 'Plant Density',
  'PlantsCollected': 'Plants collected',
  'PleaseAddFields': 'Please add some fields to continue',
  'PleaseConnect': 'No internet connection detected. To login, please make sure you are connected to the internet!',
  'PleaseEnableLocation': 'Please enable Precise Location for GreenTriangle.',
  'PleaseEnablePhotoPermissions': 'In order to continue, please enable the camera in your settings',
  'PleaseEnterEmail': 'Please enter an email.',
  'PleaseEnterValue': 'Please enter a value.',
  'PleaseReviewReportBeforeSigning': 'Please review report before signing',
  'PleaseSelectClaim': 'Please select claim',
  'PleaseSelectDate': 'Please select a date.',
  'PleaseSelectFarm': 'Please select a farm to continue',
  'PleaseSelectField': 'Please select a field to continue',
  'PleaseSelectLocation': 'Please select a location to continue.',
  'PleaseSelectPolicy': 'Please select a policy to continue',
  'PleaseUpgradeLatest': 'Your version of GreenTriangle is out of date. Please upgrade to the latest version',
  'PointOrZone': 'Point/Zone',
  'Policies': 'Contracts',
  'Policy': 'Contract',
  'PolicyFormComment': 'Additional Policy Information',
  'PolicyNumber': 'Contract Number',
  'Polygon': 'Polygon',
  'Portfolio': 'Portfolio',
  'PortfolioReport': 'Portfolio report',
  'PotentialDuplicates': 'The following entries look similar. Consider selecting one of the following options',
  'Practice': 'Practice',
  'PrecipitationInfo': 'Total weekly precipitation denotes accumulated liquid and frozen water, including rain and snow, that falls to the Earth\'s surface within 7 days. It does not include fog, dew or the precipitation that evaporates in the atmosphere before it lands at the surface of the Earth.',
  'Preview': 'Preview',
  'PreviewAndSend': 'Preview & Send',
  'Previous': 'Previous',
  'PreviousDate-s': 'Previous date(s)',
  'PriceRetainedByExpert': 'Price retained by the expert',
  'Primary': 'Primary',
  'PrimaryYieldLoss': 'Primary Yield Loss',
  'PrivacyPolicy': 'Privacy Policy',
  'Processing': 'Processing',
  'Productivity': 'Productivity',
  'ProductivityInfo': 'The productivity score estimates how productive the field has been historically',
  'ROU': 'Romania',
  'RUS': 'Russia',
  'Recipients': 'Recipients',
  'RecognizedHazard': 'Recognized Hazard',
  'Reference': 'Reference',
  'Refresh': 'Refresh',
  'Region': 'Region',
  'RegionLevel': 'Region level',
  'RegionLevel_commune': 'Commune',
  'RegionLevel_country': 'Country',
  'RegionLevel_state': 'State',
  'RegionalStatistic': 'Regional Statistic',
  'Regions': 'Regions',
  'RelativeScoring': 'Relative Scoring',
  'RelativeScoringInfo': 'The relative scores reflect the difference of a field\'s score with the median score of a region\'s fields (using around 100 sample fields), while the %-ile score positions the field score within the region fields (5 being the top percentile, 1 the bottom percentile, 3 the median)',
  'Remove': 'Remove',
  'RemoveCrop': 'Remove Crop',
  'RemoveFarm': 'Remove Farm',
  'RemoveField': 'Remove Field',
  'RemoveHarvest': 'Remove Harvest',
  'RemovedGroups': 'Removed groups',
  'RenewHarvests': 'Renew harvests',
  'ReportDate': 'Report Date',
  'ReportGenerationPageComment': 'General comment on the report',
  'ReportNoFields': 'This farm doesn\'t have any fields to create a report on!',
  'Reset': 'Reset',
  'ResetLink': 'Send a reset link',
  'Restore': 'Restore',
  'Resume': 'Resume',
  'Risks': 'Risks',
  'RowDistanceCollected': 'Row distance collected',
  'RowsAndWidth': 'Rows & Width',
  'SRB': 'Serbia',
  'Sample': 'Sample',
  'Sample-s': 'Sample(s)',
  'SampleCropTitle': 'Samples by crop type',
  'SampleDate': 'Sample Date',
  'SampleFormComment': 'Comment / Observation',
  'SampleFormMissingImagesWarning': 'You did not take a photo for this sample. Please add one to continue.',
  'SampleLocation': 'Sample Location',
  'SampleLocationInaccurate': 'Inaccurate location - Please enable Precise Location, if not already. Then click "Use my location" again.',
  'SampleLocationOutsideField': 'The sample location is not within the selected field.',
  'SampleLocationPrompt': 'Select the Sample\'s Field on the map, and then hard-press on the Map to set sample location or press the button on the left to use your current location.',
  'SampleType': 'Sample type',
  'SampledArea': 'Sampled Area',
  'Samples': 'Samples',
  'SamplingDate-s': 'Sampling Date(s)',
  'Save': 'Save',
  'SaveOffline': 'Save Offline',
  'Saving': 'Saving',
  'Score': 'Score',
  'Search': 'Search',
  'SearchAddTipLabel': 'combine search terms',
  'SearchExampleDescAddedBy': 'Type an email address to show samples added by that user, as well as those samples fields and farms',
  'SearchExampleDescAddress': 'Type an address to re-center the map to that location.',
  'SearchExampleDescCropCondition': 'Type a crop condition to highlight samples where the crop condition was assessed in a certain way.',
  'SearchExampleDescFarm': 'Find a farm and its fields & samples, by name or address',
  'SearchExampleDescHarvest': 'Find all items relating to a specific harvest by entering a crop type, season, or year. If you enter a crop type, results will be limited to this year. You can search for older harvest years in a second search.',
  'SearchExampleDescPolicy': 'Find all items relating to a contract number',
  'SearchExampleLabelAddedBy': 'find samples added by an email address',
  'SearchExampleLabelAddress': 'city, address or country',
  'SearchExampleLabelCropCondition': 'find samples for a given crop condition (e.g. "poor")',
  'SearchExampleLabelFarm': 'search by farm name and address',
  'SearchExampleLabelHarvest': 'search by harvest, e.g. "wheat"',
  'SearchExampleLabelPolicy': 'search by contract number',
  'SearchExampleLabelUserGroup': 'find items in specific portfolio(s)',
  'SearchForClaimNumber': 'Search for claim number',
  'SearchVisit': 'Find unassigned visit',
  'Secondary': 'Secondary',
  'SecondaryYieldLoss': 'Secondary Yield Loss',
  'SeedWeightPerHead': 'Seed weight per head',
  'Select': 'Select',
  'SelectAll': 'Select All',
  'SelectAppellation': 'Select appellation',
  'SelectCostType': 'Select cost type',
  'SelectCropCondition': 'Select crop condition',
  'SelectCropType': 'Select the Crop Type',
  'SelectCurHarvest': 'Select current Harvest',
  'SelectDate': 'Select Date',
  'SelectFarm': 'Select a Farm',
  'SelectFarmsForOfflineAccess': 'Select farms for offline access',
  'SelectField': 'Select a Field',
  'SelectFieldCurHarvest': 'Select the field\'s current Harvest',
  'SelectFieldCurPolicy': 'Select the field\'s current Contract',
  'SelectHarvest': 'Select Harvest',
  'SelectHarvestYear': 'Select Harvest Year',
  'SelectLossCause': 'Select loss cause',
  'SelectOneGroup': 'Please select items in one portfolio only',
  'SelectPac': 'Please select a valid file (.ZIP, .XML, .KML, GeoJSON). If you are sure this file is correct, please contact us at support@green-triangle.com.',
  'SelectPolicy': 'Select a Contract',
  'SelectPortfolio': 'Select Portfolio',
  'SelectReportType': 'Select Report type',
  'SelectSampleType': 'Select Sample type',
  'SelectSubplotId': 'Select Subplot ID',
  'SelectVariety': 'Select Variety',
  'SelectVegetationStage': 'Select Vegetation Stage (BBCH)',
  'SelectVisitType': 'Select Visit type',
  'SelectWillOverwriteShape': 'Are you sure you want to select this shape? The current shape will be overwritten.',
  'SelectYear': 'Select Year',
  'SelectedAppellation': 'Selected appellation',
  'SelectedCrops': 'Selected crops',
  'SelectedField': 'Selected field',
  'Send': 'Send',
  'SendFeedback': 'Send Feedback',
  'SendTo': 'Send to',
  'Sending': 'Sending',
  'Sent': 'Sent',
  'Settings': 'Settings',
  'SevereHailRisk': 'Severe Hail Damage Risk',
  'SeverePrecipitation': 'Severe precipitation (>3 in/wk)',
  'SevereWind': 'Hurricane (>73 mph)',
  'ShapeTracerAddPoint': 'Press on the map to add a point or press on a point to change its position or to delete it.',
  'ShapeTracerEditPoint': 'Move the point via the white hand or press anywhere else to go back to adding points.',
  'Share': 'Share',
  'ShowAll': 'Show all',
  'ShowDataOnMap': 'Show data on map',
  'ShowLastAdded': 'Show Last Added',
  'ShowOnMap': 'Show on map',
  'ShowSamplesAddedIn': 'Show samples added in the last:',
  'SignAndClose': 'Sign & Close',
  'SignatoryName': 'Name of signatory',
  'SignatoryNames': 'Names of signatories',
  'Signature': 'Signature',
  'Signatures': 'Signatures',
  'SnowIce': 'Snow/Ice',
  'SoilMoistureInfo': 'The Soil Moisture Anomaly Index quantifies the moisture condition at 16 inch depths in the soil for a 10-day period relative to the long term average (10 years) over the same period.',
  'SomethingWentWrongError': 'Something went wrong.',
  'SowingDate': 'Sowing Date',
  'SowingDensity': 'Sowing Density',
  'SpaceBetweenPlants': 'Space between plants',
  'SpaceBetweenRows': 'Space between rows',
  'SquareArea': 'Square\'s Area',
  'SquareMeterCut': 'Square Meter Cut / Plant Density',
  'SquareSide': 'Square\'s Side',
  'SquareSize': 'Square\'s Size',
  'Stability': 'Stability',
  'StabilityInfo': 'The stability score measures how stable a field\'s inferred productivity has been through time',
  'StartDate': 'Start Date',
  'StartingYield': 'Feasible Yield',
  'Status': 'Status',
  'SubjectToValidationOfReseedingByExpert': 'Subject to validation of re-seeding by the expert',
  'Subplots': 'Subplots',
  'Summary': 'Summary',
  'SupportedSurface': 'Supported surface',
  'SureAddSampleWithoutField': 'Are you sure you want to add a sample without a field?',
  'SureCancel': 'Are you sure you want to cancel before saving your data?',
  'SureCloseVisit': 'Are you sure you want to close the visit? The report has not been signed.',
  'SureCloseVisitPartial': 'Are you sure you want to close the visit? The report has only been partially signed.',
  'SureDeleteFarm': 'Are you sure you want to delete this farm?',
  'SureDeleteField': 'Are you sure you want to delete this field?',
  'SureDeleteHarvest': 'Are you sure you want to delete this harvest?',
  'SureDeleteSample': 'Are you sure you want to delete this sample?',
  'SureDeleteVisit': 'Are you sure you want to delete this visit?',
  'SureReset': 'Are you sure you want to reset? You will lose all your offline changes.',
  'SureSelectObject': 'Are you sure you want to select the following instead:',
  'SureSignOut': 'Are you sure you want to sign out? You will lose all offline changes.',
  'SurfacePAC': 'Surface PAC',
  'SurfaceTemperatureInfo': 'This layer displays weekly minimum and maximum land surface temperatures indicating the risk of frost and heat waves.',
  'Syncing': 'Syncing',
  'TableNoRowsFound': 'No rows found',
  'TableOf': 'of',
  'TablePage': 'Page',
  'TableRows': 'rows',
  'TelepacAvailableFor': 'Telepac uploaded for',
  'TelepacNr': 'Telepac number',
  'TelepacReport': 'PAC Import',
  'TemperatureInfo': 'This layer displays weekly minimum and maximum 2m air temperatures indicating the risk of frost and heat waves.',
  'Tertiary': 'Tertiary',
  'TertiaryYieldLoss': 'Tertiary Yield Loss',
  'ThousandKernelWeightGrams': '1000-kernel Weight (g)',
  'TopNCropsByHarvestArea': 'Top crops by harvest area',
  'Total': 'Total',
  'TotalArea': 'Total Area',
  'TotalCultivatedArea': 'Cultivated area (total)',
  'TotalEarsDetected': 'Total Ears Detected',
  'TotalHarvestArea': 'Total harvest area',
  'TotalLoss': 'Total loss',
  'TotalYieldPctLoss': 'Total Yield Loss (%)',
  'UKR': 'Ukraine',
  'URY': 'Uruguay',
  'USA': 'USA',
  'UnableToUpload': 'Unable to upload',
  'UnassignMyself': 'Unassign myself',
  'Uncategorized': 'Uncategorized',
  'Undo': 'Undo',
  'UnguaranteedLosses': 'Unguaranteed Losses',
  'Unit': 'Unit',
  'UnitsPerPlant': 'Units per plant',
  'UnknownErrorOccurred': 'An unknown error occurred',
  'UpcomingInspection': 'Upcoming inspection',
  'UpdateMemberships': 'Update memberships',
  'UploadPACFile': 'Upload PAC File',
  'Usage': 'Usage',
  'UseMyLocation': 'Use my location',
  'User': 'User',
  'UserAdminNoAccess': 'There is no overlap between the selected users customers and your user-admin rights.',
  'UserAdminNoCommon': 'The selected users have no common company.',
  'UserAdministration': 'User Administration',
  'UserGroup': 'Portfolio',
  'Validation': 'Validation',
  'VegetationInfo': 'The vegetation health anomaly provide an alternative measure of the relative vegetation health compared to previous years (since 2001). This indicator can be used to monitor the areas where vegetation may be stressed, as a proxy to detect potential drought.',
  'VegetationStage': 'Vegetation Stage',
  'VeryHighPrecipitation': 'Very high precipitation (2-3 in/wk)',
  'VeryLowPrecipitation': 'Very low precipitation (0.2-0.4 in/wk)',
  'ViewThisInstead': 'Would you like to exit this form and open the following instead:',
  'VinificationRatio': 'Vinification Ratio (kg/hL)',
  'Visit': 'Visit',
  'VisitAssign': 'Assign Visit',
  'VisitCandidateStatus_accepted': 'Accepted',
  'VisitCandidateStatus_assigned': 'Assigned',
  'VisitCandidateStatus_rejected': 'Rejected',
  'VisitList': 'My Visits',
  'VisitMode': 'Visit Mode',
  'VisitReport': 'Visit Report',
  'VisitReportEmailInfoMsg': 'This will send the signed visit report to the selected email addresses.',
  'VisitType': 'Visit Type',
  'VisitValidation': 'Visit Validation',
  'Visited': 'Visited',
  'Visits': 'Visits',
  'Volatility': 'Volatility',
  'WebApp': 'Web App',
  'WeightPerPlant': 'Average weight per plant',
  'WeightPerUnit': 'Weight per unit',
  'WeightedAverage': 'Weighted average',
  'WindInfo': 'The maximum weekly wind gust layer represents the maximum wind gust recorded within the last 7 days at a height of 30 foot above the surface of the Earth.',
  'Withdrawal': 'Withdrawal',
  'WouldLikeToRestore': 'We found data for this form that was not saved due to a crash or update. Would you like to recover this data?',
  'Year': 'Year',
  'YearEg': 'Year (e.g. 2019)',
  'Yes': 'Yes',
  'YieldAndLoss': 'Yield & Loss',
  'YieldCalcUnsupportedCrop': 'The calculator is not supported for this crop type. Currently, it\'s only possible to assign plant density.',
  'YieldEstimationMethod': 'Yield estimation method',
  'YieldLoss': 'Yield Loss',
  'YieldLossInputHidden': 'Yield loss input is disabled, because loss cause is not recognized.',
  'YieldRatio': 'Yield ratio',
  'YieldTooLarge': 'Yield value too large',
  'YouMayTryAgain': 'You may try again',
  'ZAF': 'South Africa',
  'ZoomInViewLayer': 'To see this layer, zoom in.',
  'ZoomOutViewLayer': 'To see this layer, zoom out.',
  'additional-costs': 'Additional Costs ($)',
  'agroforestry': 'Agroforestry',
  'alfalfa': 'Alfalfa',
  'allSynced': 'Everything has been synced with the database',
  'almond': 'Almond',
  'ananas': 'Ananas',
  'angelica': 'Angelica',
  'anise': 'Anise',
  'apples': 'Apples',
  'apples-cider': 'Apples (cider)',
  'apples-pre-prod': 'Apples (5 years or less)',
  'apricots': 'Apricots',
  'apricots-pre-prod': 'Apricots (5 years or less)',
  'aromatic-plants': 'Aromatic plants',
  'artichoke': 'Artichoke',
  'asparagus': 'Asparagus',
  'avocado': 'Avocado',
  'bananas': 'Bananas',
  'barley': 'Barley',
  'barley-6-row': '6-row barley',
  'barley-malting': 'Barley (Malting)',
  'barley-seeds': 'Barley (seeds)',
  'base': 'Base',
  'basic': 'Basic',
  'basil': 'Basil',
  'bbch-00-grapes': 'A. Winter bud',
  'bbch-05-grapes': 'B. Woolly bud',
  'bbch-09-grapes': 'C. Budbreak (green tip)',
  'bbch-11-grapes': 'D. Leaf emergence',
  'bbch-13-grapes': 'E. Leaves separated',
  'bbch-53-grapes': 'F. Inflorescences visible',
  'bbch-55-grapes': 'G. Inflorescences separated',
  'bbch-57-grapes': 'H. Flowers separated',
  'bbch-64-grapes': 'I. Bloom',
  'bbch-71-grapes': 'J. Fruit set',
  'bbch-75-grapes': 'K. Berries pea size',
  'bbch-77-grapes': 'L. Berries touching',
  'bbch-81-grapes': 'M. Veraison',
  'bbch-89-grapes': 'N. Maturity',
  'bbch-93-grapes': 'O. Cane maturation/Leaf fall',
  'beans': 'Beans',
  'beans-dry': 'Dry beans',
  'beans-green': 'Green beans',
  'beans-red': 'Red beans',
  'beans-seeds': 'Beans (seeds)',
  'beans-white': 'White beans',
  'beetroot': 'Beetroot',
  'benchmark-yield': 'Benchmark Yield',
  'black-medick': 'Black medick',
  'blackberry': 'Blackberry',
  'blackcurrant': 'Blackcurrant',
  'blueberry': 'Blueberry',
  'broccoli': 'Broccoli',
  'bromes': 'Bromes',
  'buckwheat': 'Buckwheat',
  'burdock': 'Burdock',
  'cabbages': 'Cabbages',
  'cabbages-feed': 'Cabbages (feed)',
  'cabbages-red': 'Red cabbage',
  'camelina': 'Camelina',
  'caraway': 'Caraway',
  'cardoon': 'Cardoon',
  'carob': 'Carob',
  'carrots': 'Carrots',
  'carrots-feed': 'Carrots (feed)',
  'carrots-young': 'Carrots',
  'cassava': 'Cassava',
  'castor-bean': 'Castor bean',
  'castration-costs': 'Castration Costs ($)',
  'cauliflowers': 'Cauliflowers',
  'cedrate': 'Cedrate',
  'celeri-leaves': 'Celery (leaves)',
  'celeri-root': 'Celery (root)',
  'celery': 'Celery',
  'cereals': 'Cereals',
  'chamomile': 'Chamomile',
  'chard': 'Chard',
  'cherries': 'Cherries',
  'chervil': 'Chervil',
  'chestnut': 'Chestnut',
  'chia': 'Chia',
  'chickpeas': 'Chickpeas',
  'chicory': 'Chicory',
  'chilli': 'Chilli',
  'chives': 'Chives',
  'citrus': 'Citrus fruits',
  'claim-all': 'Final closure of mission',
  'claim-delivery': 'Final closing of harvest results',
  'claim-some': 'Definitive closure of culture',
  'claims-visit': 'Final claim visit',
  'claims-visit-file': 'estimate',
  'clementines': 'Clemendines',
  'clover': 'Clover',
  'cocoa': 'Cocoa',
  'coffea': 'Coffea',
  'cold': 'Cold',
  'compensation-costs': 'Compensation Costs ($)',
  'connected': 'The app is connected to the internet!',
  'coriander': 'Coriander',
  'corn': 'Corn',
  'corn-grain': 'Corn (Grain)',
  'corn-popcorn': 'Corn (popcorn)',
  'corn-seeds': 'Corn (Seeds)',
  'corn-seeds-fertile': 'Corn (seeds, fertile)',
  'corn-seeds-sterile': 'Corn (seeds, sterile)',
  'corn-silage': 'Corn (Silage)',
  'corn-sweet': 'Sweet corn',
  'corn-sweet-seeds-fertile': 'Sweet corn (seeds, fertile)',
  'corn-sweet-seeds-sterile': 'Sweet corn (seeds, sterile)',
  'corn-waxy': 'Corn (waxy)',
  'cornsalad': 'Cornsalad',
  'cotton': 'Cotton',
  'cover-crop': 'Cover Crop',
  'cranberry': 'Cranberry',
  'cress': 'Cress',
  'crop-mon': 'Crop Monitoring',
  'crop_condition': 'Crop Condition',
  'crosne': 'Crosne',
  'cucumbers': 'Cucumbers',
  'cumin': 'Cumin',
  'cynara': 'Cynara',
  'dactylis': 'Dactylis',
  'damson': 'Damson',
  'decree': 'Official decree',
  'development': '4. Development of harvestable plant parts',
  'dill': 'Dill',
  'disease': 'Disease',
  'downpour': 'Downpour',
  'drought': 'Drought/Dry conditions',
  'eggplant': 'Eggplant',
  'elder': 'Elder',
  'endives': 'Endives',
  'excess-water': 'Excess water',
  'excessive-air-humidity': 'Excessive humidity (air)',
  'expected-loss': 'Expected Loss',
  'fallow-land-perennial': 'Fallow land (perennial)',
  'fallow-land-spring': 'Fallow land (spring)',
  'false': 'No',
  'fennel': 'Fennel',
  'fenugreek': 'Fenugreek',
  'festuca': 'Festuca',
  'field-beans': 'Field beans',
  'field-beans-seeds': 'Field beans (seeds)',
  'field-crops': 'Field Crops',
  'field-mustard': 'Field mustard',
  'figs': 'Figs',
  'fire': 'Fire',
  'first-crop': 'First Crop',
  'flageolet-bean': 'Flageolet beans',
  'flood': 'Flood',
  'flood-risk': 'Flood risk',
  'flowering': '6. Flowering',
  'fodder-legumes': 'Fodder legumes',
  'forest': 'Forest',
  'foxtail-millet': 'Foxtail millet',
  'frost': 'Frost or winter kill',
  'fruit-development': '7. Development of fruit',
  'full': 'Full Access',
  'garlic': 'Garlic',
  'gentiana': 'Gentiana',
  'germination': '0. Germination / Bud Development',
  'gherkins': 'Gherkins',
  'ginger': 'Ginger',
  'go': 'Go!',
  'good': 'Good',
  'gooseberries': 'Gooseberries',
  'gourd': 'Gourd',
  'grapefruit': 'Grapefruit',
  'grapes': 'Grapes',
  'grapes-food': 'Grapes (food)',
  'grapes-juice': 'Grapes juice',
  'grapes-must': 'Grapes must',
  'grapes-nursery': 'Vine nursery',
  'grapes-table': 'Table grapes',
  'grassland': 'Grassland',
  'grassland-perennial': 'Grassland (perennial)',
  'green-peas': 'Green peas',
  'group-allocator': 'Group Allocator',
  'hail': 'Hail',
  'harvest': 'Harvested',
  'harvest_crop': 'Crop Type',
  'harvested': 'Harvested',
  'harvesting-costs': 'Harvesting Costs ($)',
  'hazelnuts': 'Hazelnuts',
  'heat-wave': 'Heat wave',
  'heatstroke': 'Heatstroke',
  'heavy-rainfall': 'Heavy rainfall',
  'hemp': 'Hemp',
  'hemp-paper': 'Hemp (paper)',
  'hemp-seeds': 'Hemp (seeds)',
  'high-res-mapbox': 'High Res',
  'historical-yield': 'Historical Yield Ratio',
  'hop': 'Hop',
  'hyssop': 'Hyssop',
  'inflorescence': '5. Infloresence emergence / heading',
  'interfield': 'Field Comparison',
  'interfield-a': 'No vegetation',
  'interfield-b': 'Sparse vegetation',
  'interfield-c': 'Low vegetation',
  'interfield-d': 'Moderate vegetation',
  'interfield-e': 'Moderate to dense vegetation',
  'interfield-f': 'Dense vegetation',
  'interfield-g': 'High vegetation activity',
  'interfield-h': 'Very high vegetation activity',
  'interfield-i': 'Highest vegetation activity',
  'interyield': 'Yield prediction',
  'interyield-a': 'Extremely low yield',
  'interyield-b': 'Very low yield',
  'interyield-c': 'Low yield',
  'interyield-d': 'Low to moderate yield',
  'interyield-e': 'Moderate yield',
  'interyield-f': 'Moderate to high yield',
  'interyield-g': 'High yield',
  'interyield-h': 'Very high yield',
  'interyield-i': 'Highest yield',
  'intrafield': 'Intra Field Variability',
  'itemsToUpload': 'There are items to upload to the database.',
  'jerusalem-artichoke': 'Jerusalem Artichoke',
  'kale': 'Kale',
  'kiwi': 'Kiwi fruit',
  'lack-of-sunlight': 'Lack of sunlight',
  'lavandin': 'Lavandin',
  'lavender': 'Lavender',
  'leaf-development': '1. Leaf development',
  'leek': 'Leek',
  'lemon-balm': 'Lemon balm',
  'lemons': 'Lemons',
  'lentils': 'Lentils',
  'lentils-berry': 'Berry green lentils',
  'lentils-puy': 'Puy green lentils',
  'lettuce': 'Lettuce',
  'linden': 'Linden',
  'lineseed-textile-seeds': 'Linseed textile (seeds)',
  'linseed': 'Linseed',
  'linseed-oilseed': 'Linseed (oilseed)',
  'linseed-textile': 'Linseed textile',
  'linseed-textile-fiber': 'Linseed (textile/fiber)',
  'linseed-textile-seeds': 'Linseed (seeds)',
  'linseed-textile-straw': 'Linseed (textile/straw)',
  'lotus': 'Lotus',
  'lupin': 'Lupin',
  'lupin-sweet': 'Sweet lupin',
  'mandarins': 'Mandarins',
  'mangos': 'Mangos',
  'meadow-grass': 'Meadow-grass',
  'medicinal-plants': 'Medicinal plants',
  'melilotus': 'Melilotus',
  'melons': 'Melons',
  'membership_type': 'Membership Type',
  'meslin': 'Meslin',
  'millet': 'Millet',
  'mint': 'Mint',
  'mirabelles': 'Mirabelles',
  'miscanthus': 'Silvergrass',
  'mixed-cereal': 'Mixed cereals',
  'monitoring-visit': 'Monitoring visit',
  'monitoring-visit-file': 'monitoring',
  'monitoring-with-review': 'Provisioning/tracking (with management, without provisioning)',
  'monitoring-with-review-provisioning': 'Provisioning/tracking (with management, with provisioning)',
  'monitoring-without-review': 'Provisioning / monitoring (without management, without provisioning)',
  'monitoring-without-review-provisioning': 'Provisioning/monitoring (without management, with provisioning)',
  'month_0': 'Jan',
  'month_1': 'Feb',
  'month_10': 'Nov',
  'month_11': 'Dec',
  'month_2': 'Mar',
  'month_3': 'Apr',
  'month_4': 'May',
  'month_5': 'Jun',
  'month_6': 'Jul',
  'month_7': 'Aug',
  'month_8': 'Sep',
  'month_9': 'Oct',
  'most-recent': 'Most Recent',
  'mustard': 'Mustard',
  'nashi-pear': 'Nashi pear',
  'nectarines': 'Nectarines',
  'noOfflineAreas': 'To use GreenTriangle offline, it\'s best to download maps on your device. Press Yes to do it now.',
  'none': 'None',
  'normal': 'Normal',
  'notConnected': 'The app is not connected to the internet.',
  'not_available': 'Not Available',
  'not_planted': 'Not Planted',
  'nursery': 'Nursery',
  'oats': 'Oats',
  'oats-seeds': 'Oats (seeds)',
  'oilseeds': 'Oilseeds',
  'olives': 'Olives',
  'onions': 'Onions',
  'oranges': 'Oranges',
  'orchard': 'Orchard (more than 5 years)',
  'orchard-pre-prod': 'Orchard (5 years or less)',
  'orchards': 'Orchards',
  'oregano': 'Oregano',
  'other-aromatic-medicinal': 'Others aromatic or medicinal plants',
  'other-berries': 'Other berries',
  'other-cereals': 'Other cereals',
  'other-feed': 'Other fodder crops',
  'other-fruits': 'Other fruits',
  'other-industry': 'Other industrial crops',
  'other-legumes': 'Other legumes',
  'other-loss': 'Other (Loss)',
  'other-oilseed': 'Other oileaginous products',
  'other-textile': 'Other textile',
  'other-vegetables': 'Other vegetables',
  'parnsip': 'Parnsip',
  'parsley': 'Parsley',
  'peach-blood': 'Blood peach',
  'peach-flat': 'Flat peach',
  'peaches': 'Peaches',
  'peaches-pre-prod': 'Peaches (5 years or less)',
  'peanuts': 'Peanuts',
  'pears': 'Pears',
  'pears-pre-prod': 'Pears (5 years or less)',
  'peas': 'Peas',
  'peas-feed': 'Peas (feed)',
  'peas-seeds': 'Peas (seeds)',
  'peas-split': 'Split peas',
  'pepper': 'Pepper',
  'perennial': 'Perennial',
  'perennial-crops': 'Perennial Crops',
  'persimmon': 'Persimmon',
  'pest': 'Pest',
  'pickle': 'Pickled cucumber',
  'pineapples': 'Pineapples',
  'pistachio': 'Pistachio',
  'pleaseCheckForm': 'Please check form fields and try again.',
  'plum': 'Plum',
  'polyculture': 'Polyculture',
  'poor': 'Poor',
  'poppy': 'Poppy',
  'potatoes': 'Potatoes',
  'potatoes-industry': 'Potatoes (industry)',
  'potatoes-seeds': 'Potatoes (seeds)',
  'potatoes-starch': 'Potatoes (starch)',
  'potatoes-ware': 'Ware potatoes',
  'precipitation': 'Total precipitation',
  'predicted-yield': 'Predicted Yield Ratio',
  'preinspection-none': 'Risk assessment',
  'preinspection-visit': 'Pre-inspection',
  'preinspection-visit-file': 'preinspection',
  'production-costs': 'Production Costs ($)',
  'pulses': 'Pulses',
  'pumpkin': 'Pumpkin',
  'purification-costs': 'Purification Costs ($)',
  'quinces': 'Quinces',
  'quinoa': 'Quinoa',
  'radishes': 'Radishes',
  'rapeseed': 'Rapeseed',
  'rapeseed-feed': 'Rapeseed (feed)',
  'rapeseed-seeds': 'Rapeseed (seeds)',
  'raspberry': 'Raspberry',
  'ray-grass': 'Ray-grass',
  'redcurrant': 'Redcurrant',
  'remaining-crops': 'Remaining crops',
  'replanting': 'Replanting',
  'resowing-costs': 'Resowing Costs ($)',
  'rhubarb': 'Rhubarb',
  'rice': 'Rice',
  'ripening': '8. Ripening or maturity of fruit and seed',
  'rocket': 'Rocket',
  'root-crops': 'Root crops',
  'rosemary': 'Rosemary',
  'rutabaga': 'Rutabaga',
  'rye': 'Rye',
  'rye-seeds': 'Rye seeds hybrids',
  'safflower': 'Safflower',
  'sage': 'Sage',
  'sainfoin': 'Sainfoin',
  'salad': 'Salad',
  'salsify': 'Salsify',
  'salvia': 'Sage',
  'sandstorm': 'Sandstorm',
  'satellite': 'Satellite',
  'satureja': 'Satureja',
  'second-crop': 'Second Crop',
  'senescence': '9. Senescence, beginning of dormancy',
  'shallots': 'Shallots',
  'side-shoot': '2. Formation of side shoots / Tillering',
  'snow': 'Weight of snow',
  'snow-peas': 'Snow peas',
  'soil-moisture': 'Soil Moisture Anomaly',
  'sorghum': 'Sorghum (Grain)',
  'sorghum-feed': 'Sorghum (feed)',
  'sorghum-seeds': 'Sorghum (seeds)',
  'sorghum-silage': 'Sorghum (Silage)',
  'sorrel': 'Sorrel',
  'soybeans': 'Soybeans',
  'soybeans-seeds': 'Soybeans (seeds)',
  'spelt': 'Spelt',
  'spinach': 'Spinach',
  'spring': 'Spring',
  'sprouts': 'Brussels sprouts',
  'squash': 'Squash',
  'squash-max': 'Squash (Cucurbita maxima)',
  'stevia': 'Stevia',
  'storm': 'Storm',
  'strawberry': 'Strawberry',
  'sugar-beet': 'Sugar beet',
  'sugar-beet-feed': 'Sugar-beet (feed)',
  'sugar-beet-seeds': 'Sugar-beet (seeds)',
  'sugar-cane': 'Sugar Cane',
  'sugar-cane-feed': 'Sugar-cane (feed)',
  'sunburn': 'Sunburn',
  'sunflower': 'Sunflower',
  'sunflower-oilseed': 'Sunflower (oilseed)',
  'sunflower-seeds': 'Sunflower (Seeds)',
  'surface-temperature': 'Frost & Heat Risk (Surface)',
  'switchgrass': 'Switchgrass',
  'syncDataDb': 'Syncing data to the database.',
  'tarragon': 'Tarragon',
  'temperature': 'Frost & Heat Risk (Air)',
  'thistle': 'Thistle',
  'thyme': 'Thyme',
  'timothy-grass': 'Timothy-grass',
  'tobacco': 'Tobacco',
  'tobacco-blond': 'Blond tobacco',
  'tobacco-brown': 'Brown tobacco',
  'tobacco-burley': 'Tobacco Burley',
  'tobacco-virginie': 'Tobacco Virginie',
  'tomatoes': 'Tomatoes',
  'tomatoes-processing': 'Tomatoes (industry)',
  'tons': 't',
  'tornado': 'Tornado',
  'triticale': 'Triticale',
  'triticale-seeds': 'Triticale (seeds)',
  'true': 'Yes',
  'turmeric': 'Turmeric',
  'turnip': 'Turnip',
  'turnip-feed': 'Turnip (feed)',
  'unit': 'unit',
  'unknown': 'Unknown',
  'urtica': 'Nettles',
  'user-admin': 'User Manager',
  'valerian': 'Valerian',
  'validation-visit': 'Preliminary claim visit',
  'validation-visit-file': 'occurrence',
  'validation-with-review': 'Occurrence with management',
  'validation-without-review': 'Occurrence without management',
  'vanilla': 'Vanilla',
  'vegetables': 'Vegetables',
  'vegetation': 'Vegetation Anomaly',
  'vegetative-growth': '3. Stem elongation / Rosette growth',
  'verbena': 'Verbena',
  'very_good': 'Very Good',
  'very_poor': 'Very Poor',
  'vetch': 'Vetch',
  'vin-de-table': 'Table wine',
  'walnuts': 'Walnuts',
  'watermelon': 'Watermelon',
  'weeds': 'Weeds',
  'wheat': 'Wheat (Soft)',
  'wheat-einkorn': 'Einkorn wheat',
  'wheat-feed': 'Wheat (feed)',
  'wheat-hard': 'Wheat (Hard)',
  'wheat-hard-seeds': 'Hard-wheat (seeds)',
  'wheat-seeds': 'Wheat (seeds)',
  'wheat-top': 'Wheat (Soft - improved milling)',
  'wild-animals': 'Wild animals',
  'wind': 'Wind',
  'winter': 'Winter',
  'yams': 'Yams',
  'zucchini': 'Zucchini',
  '-': '-',
  'UnknownLoss': 'Unknown (Loss Cause)',
  'acres': 'ac',
  'added_by': 'Added By',
  'added_on': 'Added On',
  'arrobas-bra': '@/ha',
  'bags-per-hectare': 'sc/ha',
  'bales-per-acre': 'bales/ac',
  'bra_agree_with_result': 'Você concorda com o resultado apurado?',
  'bra_average': 'Média (tipo 2)',
  'bra_clay': 'Argiloso (tipo 3)',
  'bra_insured_facilitated': 'O segurado facilitou o processo de amostragem? (Se não, explique nos comentários)',
  'bra_insured_implemented_zarc_window': 'O segurado implantou a cultura dentro da janela de indicação do ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_last_liming_plastering_date': 'Quando foi feita a última calagem e gessagem na área segurada? (descrever a frequência da análise do solo, qual o perfil da camada do solo é trabalhada, tipo de calcário utilizado, faz uso de AP, já utilizou gesso na lavoura, etc)',
  'bra_losses_from_rnc': 'O perito constatou prejuízos por RNC? Explique os percentuais?',
  'bra_manual': 'Manual',
  'bra_mechanized': 'Mecanizada',
  'bra_necessary_to_subdivide': 'Foi necessário a subdivisão dos itens segurados? (Se sim, explique nos comentários)',
  'bra_planned_location': 'A lavoura foi implantada em local previsto? (Se não, explique nos comentários)',
  'bra_recommended_risk_location': 'A cultivar/variedade semeada é recomendada para o local do risco, conforme ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_sampling_type': 'Qual o tipo de amostragem utilizada para estimativa de produtividade da lavoura?',
  'bra_sandy': 'Arenoso (tipo 1)',
  'bra_soil_type': 'Tipo de solo',
  'bra_treatments_effective': 'Os tratos culturais foram efetivos no manejo da cultura?',
  'bra_uniform_management': 'O manejo empregado na lavoura segurada é igual das áreas de recurso próprio? (Se não, explique nos comentários)',
  'bra_visual': 'Visual',
  'brl': 'R$',
  'bushels-per-acre': 'bu/ac',
  'centimeters': 'cm',
  'chf': 'Fr.',
  'corn-grain-white': 'Corn (Grain) (white)',
  'corn-grain-yellow': 'Corn (Grain) (yellow)',
  'corn-silage-white': 'Corn (Silage) (white)',
  'corn-silage-yellow': 'Corn (Silage) (yellow)',
  'crop_id': 'Crop Type',
  'custom-a': 'Municipality Median Vegetation',
  'decitons-per-hectare': 'qx/ha',
  'development-cereals': '4. Development of harvestable plant parts',
  'development-sorghum': '4. Development of harvestable plant parts',
  'development-sugar-beet': '4. Development of harvestable plant parts',
  'eur': '€',
  'eur-per-hectoliter': '€/hL',
  'eur-per-ton': '€/t',
  'farm_id': 'Farm',
  'feet': 'ft',
  'flowering-cereals': '6. Flowering',
  'flowering-corn': '6. Flowering',
  'flowering-fruits': '6. Flowering',
  'flowering-grapes': '6. Flowering',
  'flowering-legumes': '6. Flowering',
  'flowering-rapeseed': '6. Flowering',
  'flowering-sorghum': '6. Flowering',
  'flowering-soybeans': '6. Flowering',
  'flowering-sunflower': '6. Flowering',
  'fruit-development-cereals': '7. Development of fruit',
  'fruit-development-corn': '7. Development of fruit',
  'fruit-development-fruits': '7. Development of fruit',
  'fruit-development-grapes': '7. Development of fruit',
  'fruit-development-legumes': '7. Development of fruit',
  'fruit-development-rapeseed': '7. Development of fruit',
  'fruit-development-sorghum': '7. Development of fruit',
  'fruit-development-soybeans': '7. Development of fruit',
  'fruit-development-sunflower': '7. Development of fruit',
  'germination-cereals': '0. Germination / Bud Development',
  'germination-corn': '0. Germination / Bud Development',
  'germination-fruits': '0. Germination / Bud Development',
  'germination-grapes': '0. Germination / Bud Development',
  'germination-legumes': '0. Germination / Bud Development',
  'germination-rapeseed': '0. Germination / Bud Development',
  'germination-sorghum': '0. Germination / Bud Development',
  'germination-soybeans': '0. Germination / Bud Development',
  'germination-sugar-beet': '0. Germination / Bud Development',
  'germination-sunflower': '0. Germination / Bud Development',
  'grams': 'gr',
  'harvest_year': 'Harvest Year',
  'hectares': 'ha',
  'hectoliters-per-hectare': 'hl/ha',
  'images': 'Photos',
  'inches': 'in',
  'inflorescence-cereals': '5. Infloresence emergence / heading',
  'inflorescence-corn': '5. Infloresence emergence / heading',
  'inflorescence-fruits': '5. Infloresence emergence / heading',
  'inflorescence-grapes': '5. Infloresence emergence / heading',
  'inflorescence-legumes': '5. Infloresence emergence / heading',
  'inflorescence-rapeseed': '5. Infloresence emergence / heading',
  'inflorescence-sorghum': '5. Infloresence emergence / heading',
  'inflorescence-sunflower': '5. Infloresence emergence / heading',
  'kilograms': 'kg',
  'kilograms-per-hectare': 'kg/ha',
  'leaf-development-cereals': '1. Leaf development',
  'leaf-development-corn': '1. Leaf development',
  'leaf-development-fruits': '1. Leaf development',
  'leaf-development-grapes': '1. Leaf development',
  'leaf-development-legumes': '1. Leaf development',
  'leaf-development-rapeseed': '1. Leaf development',
  'leaf-development-sorghum': '1. Leaf development',
  'leaf-development-soybeans': '1. Leaf development',
  'leaf-development-sugar-beet': '1. Leaf development',
  'leaf-development-sunflower': '1. Leaf development',
  'loss_cause': 'Loss Cause',
  'meters': 'm',
  'ounces': 'oz',
  'percent': '%',
  'pln': 'zł',
  'policy_id': 'Contract Number',
  'policy_number': 'Contract Number',
  'ripening-cereals': '8. Ripening or maturity of fruit and seed',
  'ripening-corn': '8. Ripening or maturity of fruit and seed',
  'ripening-fruits': '8. Ripening or maturity of fruit and seed',
  'ripening-grapes': '8. Ripening or maturity of fruit and seed',
  'ripening-legumes': '8. Ripening or maturity of fruit and seed',
  'ripening-rapeseed': '8. Ripening or maturity of fruit and seed',
  'ripening-sorghum': '8. Ripening or maturity of fruit and seed',
  'ripening-soybeans': '8. Ripening or maturity of fruit and seed',
  'ripening-sunflower': '8. Ripening or maturity of fruit and seed',
  'senescence-fruits': '9. Senescence, beginning of dormancy',
  'senescence-grapes': '9. Senescence, beginning of dormancy',
  'senescence-sorghum': '9. Senescence, beginning of dormancy',
  'senescence-soybeans': '9. Senescence, beginning of dormancy',
  'senescence-sunflower': '9. Senescence, beginning of dormancy',
  'side-shoot-cereals': '2. Formation of side shoots / Tillering',
  'side-shoot-sorghum': '2. Formation of side shoots / Tillering',
  'square-centimeters': 'cm²',
  'square-feet': 'ft²',
  'square-inches': 'in²',
  'square-meters': 'm²',
  'thousand-kernel-weight-grams': '1000-kernel Weight (g)',
  'tons-per-acre': 't/ac',
  'tons-per-hectare': 't/ha',
  'units-per-acre': '/ac²',
  'units-per-ft2': '/ft²',
  'units-per-hectare': '/ha²',
  'units-per-m2': '/m²',
  'usd': '$',
  'user_group': 'Portfolio',
  'vegetative-growth-cereals': '3. Stem elongation / Rosette growth',
  'vegetative-growth-corn': '3. Stem elongation / Rosette growth',
  'vegetative-growth-fruits': '3. Stem elongation / Rosette growth',
  'vegetative-growth-legumes': '3. Stem elongation / Rosette growth',
  'vegetative-growth-rapeseed': '3. Stem elongation / Rosette growth',
  'vegetative-growth-sorghum': '3. Stem elongation / Rosette growth',
  'vegetative-growth-soybeans': '3. Stem elongation / Rosette growth',
  'vegetative-growth-sugar-beet': '3. Stem elongation / Rosette growth'
} as const;

export default function t(k: I18nSimpleKey|I18nParametric): string {
  if (typeof k == 'string' || typeof k == 'boolean') { return msgs[k]; }
  if (k === null || k == undefined) { console.error('t called with null or undefined!'); return '(∅)'; }
  switch (k.type) {
    case 'AddFarmTimeseriesMaxLimitError': return `You've reached the limit of ${k.count} offline cached farms. Please delete one to proceed.`;
    case 'AdjusterSampledFarm': return `${k.added_by} sampled farm ${k.farm_name}`;
    case 'AllocateRemainingHarvestArea': return `You can allocate another ${intl_num_2(k.remainingArea)}(${k.unit}).`;
    case 'AreaIsKm2': return `This area is ${intl_num(k.area)}km² (approximately ${intl_int(k.mb)}MB).`;
    case 'CurFieldPolicyDesc': return `Current Field Contract: ${k.policy_number}`;
    case 'CurPortfolio': return `Portfolio: ${k.user_group}`;
    case 'DeleteAlreadyAssignedVisitWarning': return `You are trying to delete a visit that is assigned to ${k.count} other LAs as well.`;
    case 'DeletedUserX': return `Deleted user "${k.user_name}"`;
    case 'DistKmAway': return `${intl_num(k.dist_km)}km away`;
    case 'DownloadExplain': return `Select an area to download, for offline use. Everything in the map will be downloaded, at all zoom levels. Note that you can add ${intl_int(k.limit_km_2)}km² at a time, for offline usage.`;
    case 'DuplicateFieldId': return `Field ${k.external_field_id} was already added.`;
    case 'DuplicateSubplotWarning': return `There is already a Subplot ID: ${k.external_harvest_id} for this farm and Harvest Year. To add a sample to it, please change your selection on the previous page, or choose another subplot ID.`;
    case 'EarsDetectedCount': return `${k.count} ears detected`;
    case 'ErrorDeletingUserXY': return `Error deleting "${k.user_name}": ${k.error_message}`;
    case 'EstimatedYieldOverrideInfo': return `Estimated yield was last overridden on ${k.date}. Note that this override is replacing the aggregated estimated yield from the samples below.`;
    case 'EstimatedYieldValueUnit': return `Estimated: ${intl_num_2(k.val)}` + t(k.unit);
    case 'ExistingHarvestContainsSamples': return `Existing harvest contains ${k.count} sample(s).`;
    case 'ExoCropUnsupported': return `This feature currently does not support ` + t(k.crop) + `.`;
    case 'FeasibleYieldValueUnit': return `Feasible: ${intl_num_2(k.val)}` + t(k.unit);
    case 'FieldFormPickedOneOfMany': return `Warning: this sample will be added only to the first of the ${intl_int(k.numFields)} added.`;
    case 'FileSizeTooBig': return `This file is too big. Please select a file less than ${k.file_size}MB.`;
    case 'ImageLocationTooFar': return `Image #${k.imageIdx} was taken ${intl_num(k.distanceMeters)}` + t(k.unit) + ` from the sample location.`;
    case 'InsuredYieldValueUnit': return `Insured: ${intl_num_2(k.val)}` + t(k.unit);
    case 'LossValue': return `Loss: ${intl_num(k.yield_loss_pct)}%`;
    case 'LossesMismatchWarning': return `The sum of losses is too large: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` over the estimate.`;
    case 'LossesToAllocate': return `Losses to allocate: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` of ${intl_num_2(k.total)}` + t(k.unit) + ` total loss.`;
    case 'NFarmsSelected': return `${intl_int(k.numFarms)} farms selected`;
    case 'NPoliciesSelected': return `${intl_int(k.numPolicies)} policies selected`;
    case 'NoAppInstalledToViewFile': return `No app installed to view ${k.file_name}`;
    case 'NoDeleteClaim': return `Before deleting this claim, you need to delete its ${intl_int(k.visitCount)} visits.`;
    case 'NoDeleteFarm': return `Before deleting this farm, you need to delete its ${intl_int(k.fieldCount)} fields, ${intl_int(k.sampleCount)} samples and ${intl_int(k.claimCount)} claims.`;
    case 'NoDeleteField': return `Before deleting this field, you need to delete its ${intl_int(k.sampleCount)} samples.`;
    case 'NoDeleteHarvest': return `Before deleting this harvest, you need to delete its ${intl_int(k.sampleCount)} samples.`;
    case 'NoDeletePolicy': return `Before deleting this policy, you need to delete its ${intl_int(k.claimCount)} claims.`;
    case 'NumPhotos': return `${intl_int(k.num_photos)} Photos`;
    case 'PasswordResetSent': return `A password reset email was sent to ${k.email}`;
    case 'PlantDensityValueUnit': return `Plant Density: ${intl_num(k.val)}` + t(k.unit);
    case 'PolicyDesc': return `Contract ${k.policy_number}`;
    case 'ReportContainsTooManyFields': return `This report may be too large, with ${intl_int(k.numFields)} fields. Consider selecting a subset of sample dates and harvest crops.`;
    case 'ReportNoHarvests': return `This farm has ${intl_int(k.numFields)} fields, but doesn't have any harvest information to report on!`;
    case 'ResetMsg': return `An email with a reset link has been sent to ${k.email}`;
    case 'SampleAbrv': return `${intl_int(k.count)} s.`;
    case 'SampleLocationTooFar': return `The sample location is ${intl_num_2(k.val)}` + t(k.unit) + ` from your start location. If you want to use your location then press "Use my location" above.`;
    case 'SampleYieldWarning': return `Please fill either ${k.column1} or ${k.column2}, if possible.`;
    case 'SearchAddTipDesc': return `If you want to search for multiple terms (e.g. wheat harvests in portfolio ${k.firstUserGroup}) you can press on the ⊕ icon to add an additional term.`;
    case 'SearchExampleDescUserGroup': return `Type a portfolio name (e.g. ${k.firstUserGroup}) to show all items in that portfolio`;
    case 'SearchFoundCount': return `Found ${intl_int(k.farmCount)} farms, ${intl_int(k.fieldCount)} fields, and ${intl_int(k.sampleCount)} samples.`;
    case 'SearchInfoText': return `You can find farms, fields, samples, and contracts by searching for them here. If you want to search for multiple terms (e.g. wheat harvests in portfolio ${k.firstUserGroup}) you can press on the ⊕ icon. Each search will then act like a filter that further narrows down the results. See the examples below for some ways to search.`;
    case 'SearchResultAddedBy': return `samples added by ${k.id}`;
    case 'SearchResultAddress': return `Center map on ${k.address}`;
    case 'SearchResultCropCondition': return `crop samples with condition ${k.id}`;
    case 'SearchResultFarm': return `farm ${k.farmDesc}`;
    case 'SearchResultHarvestCrop': return `items for ${k.id}`;
    case 'SearchResultHarvestSeason': return `items for harvests in ` + t(k.id);
    case 'SearchResultHarvestYear': return `items for harvests of ${k.id}`;
    case 'SearchResultUserGroup': return `portfolio ${k.id}`;
    case 'SelectCount': return `${k.count} selected`;
    case 'SortBy': return `Sort ${k.column} ` + t(k.id);
    case 'SureAddAnotherHarvest': return `Are you sure you want to add another harvest to this field, in addition to ${k.harvestDesc}?`;
    case 'SureDeleteFile': return `Are you sure you want to delete ${k.file_name} ?`;
    case 'SureDeletePolicy': return `Are you sure you want to delete this contract? This contract will be removed from ${intl_int(k.fieldCount)} fields and ${intl_int(k.sampleCount)} samples, but they will not be deleted.`;
    case 'SureRemoveFarm': return `Are you sure you want to delete this farm and its ${intl_int(k.fieldCount)} fields?`;
    case 'SureRemoveSelectedOfflineFarms': return `Are you sure you want to remove offline access for ${k.count} selected farms?`;
    case 'TelepacReportEmail': return `An import report has been sent to you to ${k.email}, you can also download it here.`;
    case 'TotalAffectedAreaHarvest': return `The total affected area for this harvest will be ${intl_num_2(k.newArea)}(${k.unit}) over a harvest area of ${intl_num_2(k.harvestArea)}(${k.unit}).`;
    case 'TotalAffectedAreaHarvestPrompt': return `The current affected area (samples in the past two weeks) for this harvest is: ${intl_num_2(k.curArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt2': return `These are all the affected areas for this harvest: ${k.arr}.`;
    case 'TotalLossPct': return `Total Loss: ${intl_num(k.totalLossPct)}%`;
    case 'TotalSubplotAreaBiggerThanField': return `Total subplot area (${intl_num_2(k.total_subplot_area)} ha) is bigger than field area (${intl_num_2(k.field_area)} ha).`;
    case 'UpgradeFieldHarvest': return `This field currently has a ${k.originalHarvest} harvest, which will be replaced with a ${k.upgradedHarvest} harvest, to match the contract.`;
    case 'WarnHarvestAlreadyInUse': return `Saving your changes to this existing harvest would affect ${intl_int(k.num_samples)} already existing sample(s).`;
    case 'WarningDupePolicyNumber': return `Warning: there's another contract with number ${k.policy_number}`;
    case 'YieldMismatchWarning': return `Not all damages are accounted for: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` missing.`;
    case 'YourAreGoingToDeleteUsers': return `You are going to delete ${intl_int(k.num_users)} users:`;
    case 'downloadingLayers': return `Downloading ${intl_int(k.count)} layers for offline use.`;
    case 'uploadingImages': return `Uploading sample photos to the database (${intl_int(k.left)} left).`;
    case 'AreaUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'AreaUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
    case 'FarmDesc': return `${k.farm_name}, ${k.address}`;
    case 'FarmDesc_reference': return `${k.farm_name}, ${k.address} (${k.external_farm_id})`;
    case 'SampleDesc': return `${k.sample_date}: ${intl_num(k.estimated_yield_val)}` + t(k.estimated_yield_unit) + ` ` + t(k.crop_condition);
    case 'ValueUnit': return `${intl_num(k.val)}` + t(k.unit);
    case 'ValueUnitRange': return `${intl_num(k.min_val)}-${intl_num(k.max_val)}` + t(k.unit);
    case 'YieldUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'YieldUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
  }
}